import React from 'react'
import styled from 'styled-components'
import { Button, toCssPrefix, breakpoints, Image, hexToRGBA } from '@ecommerce/shared'
import Layout from '../Layout/LayoutStaticPage'

type ButtonProps = { isDisabled?: boolean; isLoading?: boolean }

type Props = {
  bgImage: string
  pageTitle: string
  icon?: string
  title: string
  text: string
  infoText?: string
  topButtonText?: string
  topButtonAction?: () => void
  topButtonProps?: ButtonProps
  bottomButtonText?: string
  bottomButtonAction?: () => void
  bottomButtonProps?: ButtonProps
  bottomComponent?: React.ReactNode
}

const { cssPrefix, toPrefix } = toCssPrefix('SignupConfirmation__')

const Wrapper = styled.div<{ bgImage?: string }>`
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: 0;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 260px);
  padding: 55px 65px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
    z-index: -1;
  }

  .${cssPrefix} {
    &content {
      max-width: 260px;
    }

    &title {
      font-size: 24px;
      margin-bottom: 28px;
    }

    &image {
      width: 145px;
      height: 145px;
      display: block;
      margin: 35px auto;
    }

    &text {
      font-size: 18px;
      margin-bottom: 30px;
    }

    &info {
      font-size: 15px;
    }

    &top-action,
    &bottom-action {
      width: 250px;
    }

    &top-action {
      margin-bottom: 15px;
    }

    &action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    padding: 75px 130px 120px;

    .${cssPrefix} {
      &content {
        max-width: 350px;
      }

      &title {
        font-size: 34px;
        margin-bottom: 55px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
  }
`

const SignupConfirmationPage = ({
  bgImage,
  pageTitle,
  icon,
  title,
  text,
  infoText,
  topButtonText,
  topButtonAction,
  topButtonProps = {},
  bottomButtonAction,
  bottomButtonText,
  bottomButtonProps = {},
  bottomComponent = null,
}: Props) => {
  return (
    <Layout title={pageTitle}>
      <Wrapper bgImage={bgImage} className={cssPrefix}>
        <div className={toPrefix('content')}>
          <h1 className={toPrefix('title')}>{title}</h1>
          <p className={toPrefix('text')}>{text}</p>
          {icon && <Image className={toPrefix('image')} alt="signup-confirmation" src={icon} />}
          {infoText && <p className={toPrefix('info')}>{infoText}</p>}
          <div className={toPrefix('action-buttons')}>
            {topButtonText && topButtonAction ? (
              <Button {...topButtonProps} onClick={topButtonAction} className={toPrefix('top-action')}>
                {topButtonText}
              </Button>
            ) : null}
            {bottomButtonText && bottomButtonAction ? (
              <Button
                {...bottomButtonProps}
                onClick={bottomButtonAction}
                btnColor="white"
                btnType="secondary"
                className={toPrefix('bottom-action')}
              >
                {bottomButtonText}
              </Button>
            ) : null}
          </div>
          {bottomComponent}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default SignupConfirmationPage
