import { TemplateSignup } from '../../types/PgPages'

export const formatRedirectURL = (cityId: number) => {
  return `${window.location.origin}/registro/exito?cityId=${cityId}`
}

export const extractPageData = (data: { allContentfulPgPage: { edges: Array<{ node: TemplateSignup }> } }) => {
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node

  return { pageData, template: pageData?.template }
}
